import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { View } from "ollui";

const PreviewCompatibleImage = ({ imageInfo, extend = {} }) => {
  const { alt = "", childImageSharp, image } = imageInfo;

  if (!!image && !!image.childImageSharp) {
    return (
      <View
        as={Img}
        extend={extend}
        fluid={image.childImageSharp.fluid}
        alt={alt}
      />
    );
  }

  if (!!childImageSharp) {
    return (
      <View as={Img} extend={extend} fluid={childImageSharp.fluid} alt={alt} />
    );
  }

  if (!!image && typeof image === "string")
    return <View as="img" extend={extend} src={image} alt={alt} />;

  return null;
};

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
};

export default PreviewCompatibleImage;
